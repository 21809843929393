<template></template>

<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "customer-group-mixin",
  data: () => ({}),
  computed: {
    ...mapState(["group_options", "group_ids_map"]),
  },
  mixins: [HttpCommonMixin],
  methods: {
    ...mapMutations(["setGroupOptions"]),
    createGroup(name, parent_id) {
      console.log("createGroup");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customer_groups/`;
      let data = {
        name: name,
        parent_id: parent_id,
      };
      this.doHttpPost(url, data, (res) => {
        this.refreshGroup();
      });
    },
    updateGroup(id, name, parent_id) {
      console.log("updateGroup");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customer_groups/${id}`;
      let data = {
        name: name,
        parent_id: parent_id,
      };
      this.doHttpPut(url, data, (res) => {
        this.refreshGroup();
      });
    },
    removeGroup(id) {
      console.log("removeGroup");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customer_groups/${id}`;
      this.doHttpDelete(url, (res) => {
        this.refreshGroup();
      });
    },
    getAllGroups() {
      this.setResult(this.group_options);
    },
    refreshGroup() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customer_groups/`;
      this.doHttpGet(url, null, (res) => {
        this.setGroupOptions(res);
        this.getAllGroups();
      });
    },
    setResult(data) {},
    mapGroupOptions(data) {
      return data.map((item) => ({
        id: item.id,
        label: item.name,
        children: this.mapGroupOptions(item.children),
        parent_id: item.parent_id,
      }));
    },
    getLabelById(id) {
      let label = "";
      const findLabel = (options) => {
        options.forEach((item) => {
          if (item.id == id) {
            label = item.display;
          } else if (item.children) {
            findLabel(item.children);
          }
        });
      };
      findLabel(this.group_options);
      return label;
    },
    getParentIdById(id) {
      let parent_id = null;
      const findParentId = (options) => {
        options.forEach((item) => {
          if (item.id == id) {
            parent_id = item.parent_id;
          } else if (item.children) {
            findParentId(item.children);
          }
        });
      };
      findParentId(this.group_options);
      return parent_id;
    },

    // cover the  this.group_options to {id: label}
    getGroupOptions() {
      let options = {};
      const mapOptions = (group_options) => {
        group_options.forEach((item) => {
          options[item.id] = item.label;
          if (item.children) {
            mapOptions(item.children);
          }
        });
      };
      mapOptions(this.group_options);
      return options;
    },
    getChildrenById(id) {
      let children = [];
      const findChildren = (options) => {
        options.forEach((item) => {
          if (item.id == id) {
            children = item.children;
          } else if (item.children) {
            findChildren(item.children);
          }
        });
      };
      findChildren(this.group_options);
      return children;
    },
    filterSearchInfo(searchInfo) {
      let info = Object.assign({}, searchInfo);
      // check if all variables is null of the searchInfo
      let isNull = true;
      for (const key in info) {
        let value = info[key];
        if (value === null) {
          continue;
        }
        // if value is string
        if (typeof value === "string") {
          value = value.trim();
          if (value !== null && value !== undefined && value.length >= 1) {
            isNull = false;
            break;
          }
        } else if (typeof value === "number") {
          if (value > 0) {
            isNull = false;
            break;
          }
        }
      }
      if (isNull) {
        return null;
      }
      return info;
    },
  },
};
</script>
